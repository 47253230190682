import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'usageQuarters'

const VIEW_ADMIN = 'admin'
// const VIEW_SUPPORT = 'support'
// const VIEW_ADVISOR = 'advisor'

class QuarterService {
  constructor (view) {
    this.view = view
  }

  clusters (params = {}) {
    params.view = this.view
    params.type = 'clusters'
    return DefaultApiService.list(ENDPOINT, params)
  }

  quarters (params = {}) {
    params.view = this.view
    params.type = 'quarters'
    return DefaultApiService.list(ENDPOINT, params)
  }
}

export default QuarterService
export const quarterServiceForAdminView = new QuarterService(VIEW_ADMIN)
// export const exportServiceForSupportView = new ExportService(VIEW_SUPPORT)
// export const exportServiceForAdvisorView = new ExportService(VIEW_ADVISOR)
