<template>
  <div class="admin" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t("comp.admin.title") }}</h1>
    <b-tabs content-class="mt-3" v-model="tabIndex">
      <b-tab :title="$t('users.title')" active>
        <Users/>
      </b-tab>
      <b-tab :title="$t('accounts.title')" lazy>
        <Accounts @gotoProject="handleGoto($event)"/>
      </b-tab>
      <b-tab :title="$t('projects.title')" lazy>
        <Projects :presetFilter="gotoID" @resetPreset="resetGoto"/>
      </b-tab>
      <b-tab :title="$t('requests.title')" lazy v-if="false"> <!-- don't show requests for the moment -->
        <Requests/>
      </b-tab>
      <b-tab :title="$t('invitations.title')" lazy>
        <Invitations/>
      </b-tab>
      <b-tab :title="$t('vouchers.title')" lazy>
        <Vouchers/>
      </b-tab>
      <b-tab :title="$t('domains.title')" lazy>
        <Domains/>
      </b-tab>
      <b-tab :title="$t('resources.title')" lazy>
        <Resources/>
      </b-tab>
      <b-tab :title="$t('import.title')" lazy>
        <Import/>
      </b-tab>
      <b-tab :title="$t('export.title')" lazy>
        <Export/>
      </b-tab>
      <b-tab :title="$t('quarters.title')" lazy>
        <Quarters/>
      </b-tab>
      <b-tab :title="$t('other.title')" lazy>
        <Other/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Users from '@/components/admin/Users.vue'
import Accounts from '@/components/admin/Accounts.vue'
import Projects from '@/components/admin/Projects.vue'
import Domains from '@/components/admin/Domains.vue'
import Requests from '@/components/admin/Requests'
import Import from '@/components/admin/Import'
import Export from '@/components/admin/Export'
import Invitations from '@/components/admin/Invitations'
import Resources from '@/components/admin/Resources'
import Vouchers from '@/components/admin/Vouchers'
import Other from '@/components/admin/Other'
import Quarters from '@/components/admin/Quarters'

export default {
  name: 'Admin',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Invitations,
    Import,
    Export,
    Requests,
    Users,
    Accounts,
    Projects,
    Domains,
    Resources,
    Vouchers,
    Other,
    Quarters
  },
  data () {
    return {
      tabIndex: 0,
      gotoID: ''
    }
  },
  watch: {
    tabIndex (newIndex, oldIndex) {
      if (this.gotoID !== '' && oldIndex === 2) {
        // If tab changed from tabIndex 2 and goto set: reset
        this.resetGoto()
      }
    }
  },
  computed: {
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('admin.title')} | ${this.$i18n.t('app.title')}`
      }
      return null
    }
  },
  methods: {
    handleGoto (ID) {
      this.gotoID = ID
      this.tabIndex = 2
    },
    resetGoto () {
      this.gotoID = ''
    }
  }
}
</script>
