<template>
  <div class="export" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('export.title') }}</h1>

    <b-card class="mb-4">
      <template #header>
        <b-row>
          <b-col cols="12">
            <b-icon-file-earmark-spreadsheet class="mr-1"/>
            {{ $t('comp.export.usagestats.info') }}
          </b-col>
        </b-row>

        <hr class="my-2"/>

        <b-row class="mb-2" align-v="center" cols="1" cols-sm="2" cols-md="4">
          <b-col>
            <ProjectSelect class="w-100" view="admin" :loadValidity="false" :selected-projects.sync="usageProjects"/>
          </b-col>
          <b-col>
            <b-form-checkbox v-model="switchDetailed" name="switchDetailedd" switch>
              <b>{{ $t('comp.export.usagestats.mode.label') }}:</b>
              {{ switchDetailed ? $t('comp.export.usagestats.detailed.label') : $t('comp.export.usagestats.usageonly.label') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b class="mr-2">{{ $t('comp.export.usagestats.from') }}:</b>
            <b-form-select class="w-auto" v-model="exportUsageFrom.mon"  :options="switchDetailed ? exportMonthOptions : exportQuarterOptions" :disabled="qinto"/>
            <b-form-select class="mr-2 w-auto" v-model="exportUsageFrom.year" :options="exportYearOptions" :disabled="qinto"/>
            <b-button @click="resetSelection('from')" variant="secondary" class="w-auto" :title="$t('comp.export.usagestats.reset')" :disabled="qinto">
              <b-icon-arrow-counterclockwise shift-v="-1"/> <div class="d-inline d-md-none d-xl-inline"> {{ $t('comp.export.usagestats.reset') }} </div>
            </b-button>
          </b-col>
          <b-col>
            <b class="mr-2">{{ $t('comp.export.usagestats.until') }}:</b>
            <b-form-select class="w-auto" v-model="exportUsageTo.mon"  :options="switchDetailed ? exportMonthOptions : exportQuarterOptions" :disabled="qinfrom"/>
            <b-form-select class="mr-2 w-auto" v-model="exportUsageTo.year" :options="exportYearOptions" :disabled="qinfrom"/>
            <b-button @click="resetSelection('to')" variant="secondary" class="w-auto" :title="$t('comp.export.usagestats.reset')" :disabled="qinfrom">
              <b-icon-arrow-counterclockwise shift-v="-1"/> <div class="d-inline d-md-none d-xl-inline"> {{ $t('comp.export.usagestats.reset') }} </div>
            </b-button>
          </b-col>
        </b-row>

        <b-row align-v="center" cols="1" cols-sm="2" cols-md="4">
          <b-col>
            <ResourceSelect :selected-resource.sync="exportFilterResource" view="admin" resourceType="computing"/>
          </b-col>
          <b-col>
            <ProvinceSelect :selected-province.sync="exportFilterProvince" view="admin" :is-disabled="projectsSelected"/>
          </b-col>
          <b-col>
            <NhrDomainCategorySelect :selected-nhr-domain-category.sync="exportFilterCategory" view="admin" :is-disabled="projectsSelected"/>
          </b-col>
          <b-col>
            <b-form-select v-model="exportFilterAiFlag" :options="exportFilterAiOptions" :disabled="projectsSelected"/>
          </b-col>
        </b-row>

        <hr class="my-2"/>

        <b-row class="" align-v="center" cols="1" cols-sm="2" cols-md="4">
          <b-col>
            <b-button @click="getUsageStats('all', false, false)" variant="primary" class="w-100">
              {{ $t('comp.export.usagestats.label') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button @click="getUsageStats('nhr', false, false)" variant="primary" class="w-100" :disabled="projectsSelected">
              {{ $t('comp.export.usagestats.nhr.label') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button @click="getUsageStats('nhr', true, false)" variant="primary" class="w-100" :disabled="projectsSelected">
              {{ $t('comp.export.usagestats.nhrlargescale.label') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button @click="getUsageStats('nhr', false, true)" variant="primary" class="w-100" :disabled="projectsSelected">
              {{ $t('comp.export.usagestats.nhrfau.label') }}
            </b-button>
          </b-col>
        </b-row>
      </template>

      <b-form-textarea v-model="usageStats" rows="6" max-rows="12"/>
    </b-card>

    <b-card>
      <template #header>
        <b-row>
          <b-col cols="12">
            <b-icon-mailbox class="mr-1"/>
            {{ $t('comp.export.mail.info') }}
          </b-col>
        </b-row>
        <hr class="my-2"/>
        <b-row class="mb-2">
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('pi')"  variant="primary" class="w-100">
             {{ $t('comp.export.pimail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('manager')" variant="primary" class="w-100">
              {{ $t('comp.export.managermail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('advisor')" variant="primary" class="w-100">
              {{ $t('comp.export.advisormail.label') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('nhrpi')"  variant="primary" class="w-100">
             {{ $t('comp.export.nhrpimail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('nhrmanager')" variant="primary" class="w-100">
              {{ $t('comp.export.nhrmanagermail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('nhrinactive')" variant="primary" class="w-100">
              {{ $t('comp.export.nhrinactivemail.label') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-form-textarea v-model="mails" rows="6" max-rows="12"/>
    </b-card>

    <b-card>
      <template #header>
        <b-row>
          <b-col cols="12">
            <b-icon-file-earmark-spreadsheet class="mr-1"/>
            {{ $t('comp.export.table.info') }}
          </b-col>
        </b-row>
        <hr class="my-2"/>
        <b-row class="mb-2">
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadInfo('pi')"  variant="primary" class="w-100">
             {{ $t('comp.export.pitable.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadInfo('manager')" variant="primary" class="w-100">
              {{ $t('comp.export.managertable.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadInfo('advisor')" variant="primary" class="w-100">
              {{ $t('comp.export.advisortable.label') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-form-textarea v-model="infos" rows="6" max-rows="12"/>
    </b-card>

    <b-card>
      <template #header>
        <b-row>
          <b-col cols="12">
            <b-icon-file-earmark-spreadsheet class="mr-1"/>
            {{ $t('comp.export.table.infomissing') }}
          </b-col>
        </b-row>
        <hr class="my-2"/>
        <b-row class="mb-2">
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMissingInfo('missingpi')"  variant="primary" class="w-100">
             {{ $t('comp.export.missingpitable.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMissingInfo('missingmanager')" variant="primary" class="w-100">
              {{ $t('comp.export.missingmanagertable.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMissingInfo('missingadvisor')" variant="primary" class="w-100">
              {{ $t('comp.export.missingadvisortable.label') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-form-textarea v-model="missinginfos" rows="6" max-rows="12"/>
    </b-card>

    <b-card  v-if="showDemoJson">
      <template #header>
        <b-row>
          <b-col cols="12">
            <b-icon-file-earmark-spreadsheet class="mr-1"/>
            {{ $t('comp.export.expiry.info') }}
          </b-col>
        </b-row>
        <hr class="my-2"/>
        <b-row class="mb-2">
          <b-col cols="2" cols-md="auto" class="pr-0">
            <b-button @click="triggerExpiry('users')"  variant="primary" class="w-100">
             {{ $t('comp.export.userexpiry.label') }}
            </b-button>
          </b-col>
          <b-col cols="2" cols-md="auto" class="pr-0">
            <b-button @click="triggerExpiry('accounts')" variant="primary" class="w-100">
              {{ $t('comp.export.accountexpiry.label') }}
            </b-button>
          </b-col>
          <b-col cols="2" cols-md="auto" class="pr-0">
            <b-button @click="triggerExpiry('projects')" variant="primary" class="w-100">
              {{ $t('comp.export.projectexpiry.label') }}
            </b-button>
          </b-col>
          <b-col cols="2" cols-md="auto" class="pr-0">
            <b-button @click="triggerExpiry('largescales')" variant="primary" class="w-100">
              {{ $t('comp.export.largescalexpiry.label') }}
            </b-button>
          </b-col>
          <b-col cols="2" cols-md="auto" class="pr-0">
            <b-button @click="triggerExpiry('usage')" variant="primary" class="w-100">
              {{ $t('comp.export.projectusage.label') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-form-textarea v-model="expiry" rows="6" max-rows="12"/>
    </b-card>

    <b-card v-if="showDemoJson">
      <template #header>
        <b-icon-list-ul class="mr-1"/> {{ $t('comp.export.examples.title') }}
      </template>
      <div class="accordion m-1" role="tablist">
        <b-card v-for="entity in Object.keys(entities)" :key="entity" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`entity-${entity}`" variant="light" class="text-left">
              <b-icon-file-earmark-arrow-down class="mr-1"/> {{ $t('comp.export.exportFor.label', {entity: $t(`comp.export.${entity}.label`)}) }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse :id="`entity-${entity}`" accordion="export" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <b-card>
                  <vue-json-pretty :data="entities[entity]"/>
                </b-card>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-card>

  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import yearSelectMixin from '@/mixins/yearSelect.mixin'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { exportServiceForAdminView } from '@/services/export.service'
import { usageServiceForAdminView } from '@/services/usage.service'
import ProjectSelect from '@/components/generic/select/ProjectSelect'
import ProvinceSelect from '@/components/generic/select/ProvinceSelect'
import ResourceSelect from '@/components/generic/select/ResourceSelect'
import NhrDomainCategorySelect from '@/components/generic/select/NhrDomainCategorySelect'

export default {
  name: 'Export',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, yearSelectMixin],
  components: {
    VueJsonPretty,
    ProjectSelect,
    ProvinceSelect,
    ResourceSelect,
    NhrDomainCategorySelect
  },
  data () {
    return {
      showDemoJson: false,
      switchDetailed: true,
      entities: {
        users: {},
        accounts: {},
        projects: {}
      },
      mails: '',
      infos: '',
      missinginfos: '',
      expiry: '',
      usageProjects: [],
      usageStats: '',
      usageFrom: { mon: null, year: null },
      usageTo: { mon: null, year: null },
      exportUsageFrom: { mon: null, year: null },
      exportUsageTo: { mon: null, year: null },
      exportUsageFilter: {},
      exportMonthOptions: [],
      exportQuarterOptions: [],
      exportYearOptions: [],
      exportFilterProvince: null,
      exportFilterResource: null,
      exportFilterCategory: null,
      exportFilterAiFlag: null,
      exportFilterAiOptions: []
    }
  },
  beforeUpdate () {
    this.exportMonthOptions = [
      { value: null, text: this.$i18n.t('comp.export.usagestats.month.placeholder'), disabled: true },
      {
        label: this.$i18n.t('comp.export.usagestats.month.label'),
        options: [
          { value: 'Jan', text: this.$i18n.t('comp.export.usagestats.month.jan') },
          { value: 'Feb', text: this.$i18n.t('comp.export.usagestats.month.feb') },
          { value: 'Mar', text: this.$i18n.t('comp.export.usagestats.month.mar') },
          { value: 'Apr', text: this.$i18n.t('comp.export.usagestats.month.apr') },
          { value: 'May', text: this.$i18n.t('comp.export.usagestats.month.may') },
          { value: 'Jun', text: this.$i18n.t('comp.export.usagestats.month.jun') },
          { value: 'Jul', text: this.$i18n.t('comp.export.usagestats.month.jul') },
          { value: 'Aug', text: this.$i18n.t('comp.export.usagestats.month.aug') },
          { value: 'Sep', text: this.$i18n.t('comp.export.usagestats.month.sep') },
          { value: 'Oct', text: this.$i18n.t('comp.export.usagestats.month.oct') },
          { value: 'Nov', text: this.$i18n.t('comp.export.usagestats.month.nov') },
          { value: 'Dec', text: this.$i18n.t('comp.export.usagestats.month.dec') }
        ]
      },
      {
        label: this.$i18n.t('comp.export.usagestats.quarter.label'),
        options: [
          { value: 'Q1', text: this.$i18n.t('comp.export.usagestats.month.qone') },
          { value: 'Q2', text: this.$i18n.t('comp.export.usagestats.month.qtwo') },
          { value: 'Q3', text: this.$i18n.t('comp.export.usagestats.month.qthree') },
          { value: 'Q4', text: this.$i18n.t('comp.export.usagestats.month.qfour') }
        ]
      },
      {
        label: this.$i18n.t('comp.export.usagestats.other.label'),
        options: [
          { value: 'H1', text: this.$i18n.t('comp.export.usagestats.month.hone') },
          { value: 'H2', text: this.$i18n.t('comp.export.usagestats.month.htwo') },
          { value: 'FY', text: this.$i18n.t('comp.export.usagestats.month.fyear') }
        ]
      }
    ]

    this.exportQuarterOptions = [
      { value: null, text: this.$i18n.t('comp.export.usagestats.quarter.placeholder'), disabled: true },
      { value: 'Q1', text: this.$i18n.t('comp.export.usagestats.month.qone') },
      { value: 'Q2', text: this.$i18n.t('comp.export.usagestats.month.qtwo') },
      { value: 'Q3', text: this.$i18n.t('comp.export.usagestats.month.qthree') },
      { value: 'Q4', text: this.$i18n.t('comp.export.usagestats.month.qfour') }
    ]

    this.exportYearOptions = [
      { value: null, text: this.$i18n.t('comp.export.usagestats.year.placeholder'), disabled: true },
      ...this.yearOptions
    ]

    this.exportFilterAiOptions = [
      { text: this.$i18n.t('comp.export.filter.aiflag.placehoder'), value: null, disabled: true },
      { text: this.$i18n.t('comp.export.filter.aiflag.null'), value: '' },
      { text: this.$i18n.t('comp.export.filter.aiflag.true'), value: 'true' },
      { text: this.$i18n.t('comp.export.filter.aiflag.false'), value: 'false' }
    ]
  },
  watch: {
    'exportUsageFrom.mon': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageFrom, 'from')
    },
    'exportUsageFrom.year': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageFrom, 'from')
    },
    'exportUsageTo.mon': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageTo, 'to')
    },
    'exportUsageTo.year': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageTo, 'to')
    },
    exportFilterProvince: function (newValue, oldValue) {
      if (newValue) this.exportUsageFilter.province = newValue
      else delete this.exportUsageFilter.province
    },
    'exportFilterResource.label': function (newValue, oldValue) {
      if (newValue) this.exportUsageFilter.resource = newValue.toLowerCase()
      else delete this.exportUsageFilter.resource
    },
    'exportFilterCategory.key': function (newValue, oldValue) {
      console.log(newValue)
      if (newValue) this.exportUsageFilter.category = newValue
      else delete this.exportUsageFilter.category
    },
    exportFilterAiFlag: function (newValue, oldValue) {
      if (newValue) this.exportUsageFilter.aiflag = newValue
      else delete this.exportUsageFilter.aiflag
    },
    switchDetailed: function (newValue, oldValue) {
      this.usageFrom = { mon: null, year: null }
      this.usageTo = { mon: null, year: null }
      this.exportUsageFrom = { mon: null, year: null }
      this.exportUsageTo = { mon: null, year: null }
    }
  },
  computed: {
    projectsSelected () {
      return _.isEmpty(this.usageProjects) === false
    },
    qinfrom () {
      if (this.switchDetailed && this?.exportUsageFrom?.mon && this.exportUsageFrom.mon.match(/^FY$|^H[12]{1}$|^Q[1-4]{1}$/)) {
        return true
      } else {
        return false
      }
    },
    qinto () {
      if (this.switchDetailed && this?.exportUsageTo?.mon && this.exportUsageTo.mon.match(/^FY$|^H[12]{1}$|^Q[1-4]{1}$/)) {
        return true
      } else {
        return false
      }
    }
  },
  beforeMount () {
    if (window.location.href.indexOf('localhost') >= 0) {
      // Development mode
      this.showDemoJson = true
      _.forEach(this.entities, (entityValue, entityKey) => {
        this.loadEntity(entityKey)
      })
    }
  },
  methods: {
    loadEntity (entity) {
      const params = { entity: entity }
      exportServiceForAdminView.demo(params).then((entityResult) => {
        this.entities[entity] = entityResult
      }).catch(error => {
        console.error(error)
      })
    },
    loadMail (type) {
      const params = { mailFor: type }
      exportServiceForAdminView.mails(params).then((mails) => {
        this.mails = mails
      }).catch(error => {
        console.error(error)
      })
    },
    loadInfo (type) {
      const params = { infoFor: type }
      exportServiceForAdminView.infos(params).then((infos) => {
        this.infos = infos
      }).catch(error => {
        console.error(error)
      })
    },
    loadMissingInfo (type) {
      const params = { infoFor: type }
      exportServiceForAdminView.missingInfos(params).then((missinginfos) => {
        this.missinginfos = missinginfos
      }).catch(error => {
        console.error(error)
      })
    },
    triggerExpiry (type) {
      const params = { expiryFor: type }
      exportServiceForAdminView.expiry(params).then((expiry) => {
        this.expiry = expiry
      }).catch(error => {
        console.error(error)
      })
    },
    handleQuarter (exportUsage, dir) {
      if (!exportUsage?.year) {
        // Local fillin
        exportUsage.year = this.thisYear
        // Global fillin
        if (dir === 'from') this.exportUsageFrom.year = this.thisYear
        else this.exportUsageTo.year = this.thisYear
      }

      if (!this.switchDetailed) {
        // POST Quarters directly
        if (dir === 'from') {
          this.usageFrom = { mon: exportUsage.mon, year: exportUsage.year }
          this.usageTo = this.exportUsageTo
        } else {
          this.usageFrom = this.exportUsageFrom
          this.usageTo = { mon: exportUsage.mon, year: exportUsage.year }
        }
      } else {
        // Resolve By Month
        switch (exportUsage?.mon) {
          case 'Q1':
            this.usageFrom = { mon: 'Jan', year: exportUsage.year }
            this.usageTo = { mon: 'Mar', year: exportUsage.year }
            break
          case 'Q2':
            this.usageFrom = { mon: 'Apr', year: exportUsage.year }
            this.usageTo = { mon: 'Jun', year: exportUsage.year }
            break
          case 'Q3':
            this.usageFrom = { mon: 'Jul', year: exportUsage.year }
            this.usageTo = { mon: 'Sep', year: exportUsage.year }
            break
          case 'Q4':
            this.usageFrom = { mon: 'Oct', year: exportUsage.year }
            this.usageTo = { mon: 'Dec', year: exportUsage.year }
            break
          case 'H1':
            this.usageFrom = { mon: 'Jan', year: exportUsage.year }
            this.usageTo = { mon: 'Jun', year: exportUsage.year }
            break
          case 'H2':
            this.usageFrom = { mon: 'Jul', year: exportUsage.year }
            this.usageTo = { mon: 'Dec', year: exportUsage.year }
            break
          case 'FY':
            this.usageFrom = { mon: 'Jan', year: exportUsage.year }
            this.usageTo = { mon: 'Dec', year: exportUsage.year }
            break
          default:
            if (dir === 'from') {
              this.usageFrom = { mon: exportUsage.mon, year: exportUsage.year }
              this.usageTo = this.exportUsageTo
            } else {
              this.usageFrom = this.exportUsageFrom
              this.usageTo = { mon: exportUsage.mon, year: exportUsage.year }
            }
        }
      }
    },
    resetSelection (dir) {
      if (dir === 'from') {
        this.exportUsageFrom = { mon: null, year: null }
        this.usageFrom = { mon: null, year: null }
        this.usageTo = this.exportUsageTo // Reset to visible selection after possible quarter selection
      } else {
        this.exportUsageTo = { mon: null, year: null }
        this.usageTo = { mon: null, year: null }
        this.usageFrom = this.exportUsageFrom // Reset to visible selection after possible quarter selection
      }
    },
    getUsageStats (type, nhrlarge, nhrfau) {
      if (this.usageFrom.mon !== null && this.usageTo.mon !== null &&
        this.usageFrom.year !== null && this.usageTo.year !== null) { // this.usageProjects.length > 0
        const statsRequest = { detailed: this.switchDetailed, projects: this.usageProjects, filter: this.exportUsageFilter, from: this.usageFrom, to: this.usageTo, nhronly: (type === 'nhr'), nhrlarge: nhrlarge, nhrfau: nhrfau }
        usageServiceForAdminView.getUsageStats(statsRequest).then((csv) => {
          this.usageStats = csv
          // this.usageFrom = { mon: null, year: null }
          // this.usageTo = { mon: null, year: null }
          this.usageProjects = []
        }).catch(error => {
          const parsed = JSON.parse(error.request.response)
          this.usageStats = parsed.error
          console.error(error)
        })
      } else {
        this.usageStats = this.$i18n.t('comp.export.usagestats.form.info')
        console.log('Fill usage stats form first!')
      }
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
