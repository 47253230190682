<template>
  <div class="quarters" v-show="true">
    <!-- messagesAvailable not feasible: too much resetting working localization -->
    <h1 class="sr-only">{{ $t("comp.quarters.title") }}</h1>
    <b-tabs content-class="mt-3">
      <b-tab v-for="cluster in clusters" :key="cluster.key" :title="cluster.key" lazy>
        <!-- Only one Subluster -->
        <QuarterTable v-if="cluster.subClusters.length === 1" :cluster="cluster.key" :subCluster="cluster.subClusters[0]"/>
        <!-- More than one Subcluster -->
        <b-tabs v-else content-class="mt-3">
          <b-tab v-for="subCluster in cluster.subClusters" :key="subCluster" :title="subCluster" lazy>
            <QuarterTable :cluster="cluster.key" :subCluster="subCluster"/>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import QuarterTable from '@/components/admin/quarters/QuarterTable.vue'
import { quarterServiceForAdminView } from '@/services/quarter.service'

export default {
  name: 'Quarters',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    QuarterTable
  },
  data () {
    return {
      clusters: []
    }
  },
  computed: {
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('quarter.title')} | ${this.$i18n.t('app.title')}`
      }
      return null
    }
  },
  created () {
    quarterServiceForAdminView.clusters().then((data) => {
      this.clusters = data
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
