<template>
  <div class="quartertable" v-show="true">
    <!-- messagesAvailable not feasible: too much resetting working localization -->
    <h1 class="sr-only">{{ $t("comp.quartertable.title") }}</h1>
    <b-table-simple class="mb-0" :id="`table-${cluster}-${subCluster}`" hover>
      <!-- Header -->
      <b-tr>
        <b-th>
          <div class="d-flex justify-content-between align-items-center">
            Project
            <b-form-input size="sm" class="w-50" v-model="filter.project" type="search" placeholder="Filter ..."/>
          </div>
        </b-th>
        <b-th>
          <div class="d-flex justify-content-between align-items-center">
            Title
            <b-form-input size="sm" class="w-50" v-model="filter.title" type="search" placeholder="Filter ..."/>
          </div>
        </b-th>
        <b-th>
          Q{{ this.quarters.at(this.quarters.indexOf(this.thisQuarter) - 3) }}/{{ this.firstYear }}
        </b-th>
        <b-th>
          Q{{ this.quarters.at(this.quarters.indexOf(this.thisQuarter) - 2) }}/{{ this.secondYear }}
        </b-th>
        <b-th>
          Q{{ this.quarters.at(this.quarters.indexOf(this.thisQuarter) - 1) }}/{{ this.thirdYear }}
        </b-th>
        <b-th>
          Q{{ this.thisQuarter }}/{{ this.thisYear }}
        </b-th>
        <b-th>
          Sum
        </b-th>
      </b-tr>
      <!-- Iterative Data : TBody per Type -->
      <b-tbody v-for="(qentry, key) in quarterData" :key="key">
        <!-- Type Sum Data -->
        <b-tr :variant="
          (key == 'total') ? 'danger' :
          (key == 'bki') ? 'info' :
          (key == 'nhr') ? 'success' :
          (key == 'fau') ? 'primary' :
          'secondary'"
        >
          <b-td>
            {{ qentry?.projects ? key.toUpperCase() : `${cluster} (${subCluster})` }}
          </b-td>
          <b-td>
            Totals
          </b-td>
          <b-td>
            {{ qentry.quarters[0] }}
          </b-td>
          <b-td>
            {{ qentry.quarters[1] }}
          </b-td>
          <b-td>
            {{ qentry.quarters[2] }}
          </b-td>
          <b-td>
            {{ qentry.quarters[3] }}
          </b-td>
          <b-td>
            {{ qentry.sum }}
          </b-td>
        </b-tr>
        <!-- Project Data -->
        <b-tr v-for="pentry in filteredProjects[key]" :key="pentry?.key">
          <b-td>
            {{ pentry?.key }}
          </b-td>
          <b-td>
            {{ pentry?.label }}
          </b-td>
          <b-td>
            {{ pentry?.quarters[0] }}
          </b-td>
          <b-td>
            {{ pentry?.quarters[1] }}
          </b-td>
          <b-td>
            {{ pentry?.quarters[2] }}
          </b-td>
          <b-td>
            {{ pentry?.quarters[3] }}
          </b-td>
          <b-td>
            {{ pentry?.sum }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <hr class="mt-0"/>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import { quarterServiceForAdminView } from '@/services/quarter.service'

export default {
  name: 'QuarterTable',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
  },
  props: {
    cluster: {
      type: String,
      required: true
    },
    subCluster: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      quarterData: {},
      filter: { project: '', title: '' },
      filteredProjects: {},
      quarters: [1, 2, 3, 4],
      thisYear: new Date().getFullYear(),
      thisMonth: new Date().getMonth()
    }
  },
  watch: {
    filter: {
      handler (val, _) {
        if (val.project.length >= 3 && val.title.length >= 3) {
          this.filteredProjects.nhr = this.quarterData?.nhr?.projects.filter((p) => p.key.includes(val.project) && p.label.includes(val.title))
          this.filteredProjects.fau = this.quarterData?.fau?.projects.filter((p) => p.key.includes(val.project) && p.label.includes(val.title))
          this.filteredProjects.bki = this.quarterData?.bki?.projects.filter((p) => p.key.includes(val.project) && p.label.includes(val.title))
        } else if (val.project.length >= 3 && val.title.length < 3) {
          this.filteredProjects.nhr = this.quarterData?.nhr?.projects.filter((p) => p.key.includes(val.project))
          this.filteredProjects.fau = this.quarterData?.fau?.projects.filter((p) => p.key.includes(val.project))
          this.filteredProjects.bki = this.quarterData?.bki?.projects.filter((p) => p.key.includes(val.project))
        } else if (val.project.length < 3 && val.title.length >= 3) {
          this.filteredProjects.nhr = this.quarterData?.nhr?.projects.filter((p) => p.label.includes(val.title))
          this.filteredProjects.fau = this.quarterData?.fau?.projects.filter((p) => p.label.includes(val.title))
          this.filteredProjects.bki = this.quarterData?.bki?.projects.filter((p) => p.label.includes(val.title))
        } else if (val.project.length < 3 && val.title.length < 3) {
          this.filteredProjects.nhr = this.quarterData?.nhr?.projects
          this.filteredProjects.fau = this.quarterData?.fau?.projects
          this.filteredProjects.bki = this.quarterData?.bki?.projects
        }
      },
      deep: true
    }
  },
  computed: {
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('quartertable.title', { cluster: this.cluster })} | ${this.$i18n.t('app.title')}`
      }
      return null
    },
    thisQuarter () {
      // getMonth() returns 0-11
      if (this.thisMonth <= 2) {
        return 1
      } else if (this.thisMonth <= 5) {
        return 2
      } else if (this.thisMonth <= 8) {
        return 3
      } else {
        return 4
      }
    },
    firstYear () {
      return this.quarters.indexOf(this.thisQuarter) === 3 ? this.thisYear : this.thisYear - 1
    },
    secondYear () {
      return this.quarters.indexOf(this.thisQuarter) >= 2 ? this.thisYear : this.thisYear - 1
    },
    thirdYear () {
      return this.quarters.indexOf(this.thisQuarter) > 0 ? this.thisYear : this.thisYear - 1
    }
  },
  created () {
    quarterServiceForAdminView.quarters({ cluster: this.cluster.toLowerCase(), subCluster: this.subCluster.toLowerCase() }).then((data) => {
      this.quarterData = data
      this.filteredProjects.nhr = data?.nhr?.projects
      this.filteredProjects.fau = data?.fau?.projects
      this.filteredProjects.bki = data?.bki?.projects
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
