<template>
  <div class="projects">
    <h1 class="sr-only">{{ $t('projects.title') }}</h1>
    <b-form v-if="showForm" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.projectId" @delete="deleteProject(form)"/>
      <b-card class="mx-2 mx-md-5" :header="formCardHeader">
        <b-form-group :label="$t('comp.projects.projectId.label')"
                      :description="$t('comp.projects.projectId.description')">
          <b-form-input v-model="form.projectId" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.title.label')" :description="$t('comp.projects.title.description')">
          <b-form-input v-model="form.title" required/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.unixGroupSelect.label')" :description="$t('comp.projects.unixgroupselect.description')">
          <UnixGroupSelect :selected-unixgroup.sync="unixGroup" view="admin"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.unixGroupId.label')"
                      :description="$t('comp.projects.unixGroupId.description')">
          <b-form-input v-model="form.unixGroupId" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.unixGroupName.label')"
                      :description="$t('comp.projects.unixGroupName.description')">
          <b-form-input v-model="form.unixGroupName" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.projecttype.label')" :description="$t('comp.projects.projecttype.description')">
          <ProjectTypeSelect :selected-projecttype.sync="form.projectType" view="admin" required/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.domain.label')" :description="$t('comp.projects.domain.description')">
          <DomainSelect :selected-domain.sync="form.domain" view="admin" required/>
        </b-form-group>
        <b-form-checkbox v-model="form.aiRelated" name="aiRelated" class="my-3" switch>
          {{ $t('comp.projects.airelated.long.label') }}
        </b-form-checkbox>
        <b-form-group :label="$t('comp.projects.province.label')"
                      :description="$t('comp.projects.province.description')">
          <ProvinceSelect :selected-province.sync="form.province" view="admin" required/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.organization.label')"
                      :description="$t('comp.projects.organization.description')">
          <OrganizationSelect :selected-organization.sync="form.organization" view="admin" required/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.pi.label')"
                      :description="$t('comp.projects.pi.description')">
          <UserSelect :selected-user.sync="form.pi" view="admin"/>
        </b-form-group>
        <b-form-checkbox v-model="form.piMuted" name="piMuted" class="my-3" switch>
          {{ $t('comp.projects.pimuted.label') }}
        </b-form-checkbox>
        <b-form-group :label="$t('comp.projects.advisors.label')"
                      :description="$t('comp.projects.advisors.description')">
          <UserSelect :selected-users.sync="form.advisors" view="admin"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.managers.label')"
                      :description="$t('comp.projects.managers.description')">
          <UserSelect :selected-users.sync="form.managers" view="admin"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.state.label')"
                      :description="$t('comp.projects.state.description')">
          <StateSelect :selected-state.sync="form.state" entity="project" view="admin"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.prefix.label')"
                      :description="$t('comp.projects.prefix.description')">
          <b-form-input type="text" v-model="form.prefix" :maxlength="prefixLength" :required="prefixRequired" :disabled="!prefixRequired"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.validFrom.label')" label-for="valid-from"
                      :description="$t('comp.projects.validFrom.description')">
          <b-form-datepicker no-flip id="valid-from" :showDecadeNav="showDateTimeDecadeNav" v-model="form.validFrom"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.validUntil.label')" label-for="valid-until"
                      :description="$t('comp.projects.validUntil.description')">
          <b-form-datepicker no-flip id="valid-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.validUntil"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.accessibleFrom.label')" label-for="accessible-from"
                      :description="$t('comp.projects.accessibleFrom.description')">
          <b-form-datepicker no-flip id="accessible-from" :showDecadeNav="showDateTimeDecadeNav" v-model="form.accessibleFrom"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.accessibleUntil.label')" label-for="accessible-until"
                      :description="$t('comp.projects.accessibleUntil.description')">
          <b-form-datepicker no-flip id="accessible-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.accessibleUntil"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.computeresources.label')" :description="$t('comp.projects.computeresources.description')">
          <ProjectResourceSelect :selected-project-resources.sync="form.computeResources" resourceType="computing" view="admin"/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.storageresources.label')" :description="$t('comp.projects.storageresources.description')">
          <ProjectResourceSelect :selected-project-resources.sync="form.storageResources" resourceType="storage" view="admin"/>
        </b-form-group>
      </b-card>
      <CommentCard :targetId="this.form.projectId.toString()" :new-comment.sync="newComment" :showTable="formEdit" view="admin"/>
    </b-form>
    <div class="overflow-auto px-2 px-md-5" v-else>
      <div class="row justify-content-between justify-content-md-start mt-md-3">
        <div class="col-12 col-md-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="addProject" variant="success">
            <b-icon-plus/> {{ $t('comp.projects.add.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-0">
          <b-button class="w-100" @click="showOnly('nhrprojects')" variant="primary">
            <b-icon-funnel-fill v-if="nhrProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showNhrOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('fauprojects')" variant="primary">
            <b-icon-funnel-fill v-if="fauProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showFauOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-0">
          <b-button class="w-100" @click="showOnly('aiprojects')" variant="primary">
            <b-icon-funnel-fill v-if="aiProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showAiOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('nonaiprojects')" variant="primary">
            <b-icon-funnel-fill v-if="nonaiProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showNonAiOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-0">
          <b-button class="w-100" @click="showOnly('missingpi')" variant="primary">
            <b-icon-funnel-fill v-if="missingPiProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showMissingPiOnly.label') }}
          </b-button>
        </div>
        <div class="col-12 col-md-auto mb-3 ml-md-auto align-self-center">
          <b-form-checkbox v-model="lessColumns" name="lessColumns" switch>
          {{ $t('comp.projects.lessColumns.label') }}
          </b-form-checkbox>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="projects-table"/>
      <b-table responsive id="projects-table" ref="projects-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="projectItemProvider" small striped hover>
        <template v-slot:cell(actions)="data">
          <div style="max-width: 100px">
            <b-button @click="editProject(data.item)" :title="$t('comp.projects.edit.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-pencil class="mr-1" shift-h="2" variant="primary"/>
            </b-button>
            <b-button @click="deleteProject(data.item)" :title="$t('comp.projects.delete.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-trash class="mr-1" shift-h="2" variant="danger"/>
            </b-button>
            <b-button v-b-modal="`accounts-modal-${data.item.projectId}`" :title="$t('comp.projects.manageaccounts.label')" variant="light"
                      size="sm" class="mt-1 mr-1">
              <b-icon-person-circle class="mr-1" shift-h="2" variant="primary"/>
            </b-button>
            <b-button v-b-modal="`usage-modal-${data.item.projectId}`" :title="$t('comp.projects.showusage.label')" variant="light"
                      size="sm" class="mt-1 mr-1">
              <b-icon-bar-chart-line class="mr-1" shift-h="2" variant="info"/>
            </b-button>
          </div>
          <b-modal :id="`usage-modal-${data.item.projectId}`" :title="$t('comp.projects.usage.display.label', { project: data.item.projectId })" size="xl">
            <div>
              <ProjectUsageInfo :project="data.item.projectId" view="admin"/> <!-- type="project" -->
            </div>
          </b-modal>
          <b-modal :id="`accounts-modal-${data.item.projectId}`" :title="$t('comp.projects.projectaccounts.label', { project: data.item.projectId, title: data.item.title })" size="xl">
            <div>
              <ProjectAccounts :project="data.item" view="admin"/>
            </div>
          </b-modal>
        </template>
        <template v-slot:cell(progress)="data">
          <UsageProgress :data="data.item.progress"/>
        </template>
        <template v-slot:cell(aiRelated)="data">
          <b-button :id="`airelated-popover${data.item.projectId}`" variant="light" size="sm" style="border-color:darkgray">
            <b-icon-cpu-fill v-if="data.item.aiRelated" shift-v="-1" variant="success"/>
            <b-icon-cpu v-else shift-v="-1" variant="secondary"/>
          </b-button>
          <b-popover :target="`airelated-popover${data.item.projectId}`" triggers="hover" placement="right">
            <template #title>{{ $t('comp.projects.airelated.long.label') }}</template>
            {{  data?.item?.aiRelated ? $t('comp.projects.airelated.yes.label') : $t('comp.projects.airelated.no.label') }}
          </b-popover>
        </template>
        <template v-slot:cell(pi)="data">
          <b-icon v-if="data?.item?.piMuted" :title="$t('comp.projects.pimuted.title')" icon="bell-slash-fill" variant="danger" shift-v="+1" scale="0.90" class="mr-1 cursor-help"/>
          {{ data?.item?.pi?.label ? data.item.pi.label : "-"}}
        </template>
        <template v-slot:cell(advisors)="data">
          <div v-if="!data?.item?.advisors || data.item.advisors.length == 0">
            -
          </div>
          <div v-else-if="data.item.advisors.length == 1">
            {{ data.item.advisors[0].label }}
          </div>
          <div v-else>
            <b-button :id="`advisors-popover${data.item.projectId}`" variant="light" size="sm" style="border-color:darkgray">
              <b-icon-people-fill shift-v="-1" variant="primary"/>
            </b-button>
            <b-popover :target="`advisors-popover${data.item.projectId}`" triggers="hover" placement="right">
              <template #title>{{ $t('comp.projects.advisors.label') }}</template>
              <ul>
                <li v-for="advisor in data.item.advisors" :key="advisor.key">{{ advisor.label }}</li>
              </ul>
            </b-popover>
          </div>
        </template>
        <template v-slot:cell(managers)="data">
          <div v-if="!data?.item?.managers || data.item.managers.length == 0">
            -
          </div>
          <div v-else-if="data.item.managers.length == 1">
            {{ data.item.managers[0].label }}
          </div>
          <div v-else>
            <b-button :id="`managers-popover${data.item.projectId}`" variant="light" size="sm" style="border-color:darkgray">
              <b-icon-people-fill shift-v="-1" variant="primary"/>
            </b-button>
            <b-popover :target="`managers-popover${data.item.projectId}`" triggers="hover" placement="right">
              <template #title>{{ $t('comp.projects.managers.label') }}</template>
              <ul>
                <li v-for="manager in data.item.managers" :key="manager.key">{{ manager.label }}</li>
              </ul>
            </b-popover>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { projectFilterMixin } from '@/mixins/tableFilter.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import { projectServiceForAdminView } from '@/services/project.service'
import { commentServiceForAdminView } from '@/services/comment.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import CommentCard from '@/components/generic/helper/CommentCard'
import StateSelect from '@/components/generic/select/StateSelect'
import UserSelect from '@/components/generic/select/UserSelect'
import ProvinceSelect from '@/components/generic/select/ProvinceSelect'
import DomainSelect from '@/components/generic/select/DomainSelect'
import OrganizationSelect from '@/components/generic/select/OrganizationSelect'
import ProjectTypeSelect from '@/components/generic/select/ProjectTypeSelect'
import UnixGroupSelect from '@/components/generic/select/UnixGroupSelect'
import ProjectResourceSelect from '@/components/generic/select/ProjectResourceSelect'
import ProjectUsageInfo from '@/components/generic/info/ProjectUsageInfo'
import ProjectAccounts from '@/components/generic/helper/ProjectAccounts'
import UsageProgress from '@/components/generic/chart/UsageProgress'

export default {
  name: 'Projects',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin, projectFilterMixin],
  components: {
    UserSelect,
    TableHeader,
    FormButtons,
    CommentCard,
    StateSelect,
    OrganizationSelect,
    ProvinceSelect,
    DomainSelect,
    ProjectTypeSelect,
    UnixGroupSelect,
    ProjectResourceSelect,
    ProjectUsageInfo,
    ProjectAccounts,
    UsageProgress
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: this.presetFilter,
      form: null,
      formEdit: false,
      formReset: null,
      showForm: false,
      showDateTimeDecadeNav: true,
      prefixLength: 4,
      prefixRequired: false,
      unixGroup: null,
      newComment: '',
      changes: [],
      lessColumns: false,
      emptyForm: {
        projectId: '',
        title: '',
        projectType: null,
        domain: null,
        province: '',
        organization: null,
        unixGroupId: null,
        unixGroupName: '',
        state: 'active',
        prefix: '',
        accessibleFrom: null,
        accessibleUntil: null,
        validFrom: null,
        validUntil: null,
        pi: null,
        piMuted: false,
        advisors: [],
        managers: [],
        computeResources: [],
        storageResources: [],
        aiRelated: false
      }
    }
  },
  props: {
    presetFilter: {
      type: String,
      default: ''
    }
  },
  watch: {
    unixGroup: function () {
      if (this.showForm) { // process only if form shown
        this.processUnixGroup()
      }
    },
    'form.projectType': function (newType, oldType) {
      if (newType) {
        this.prefixRequired = newType.label.includes('FAU')
      } else {
        this.prefixRequired = false
      }
    },
    filter (newFilter, oldFilter) {
      if (this.presetFilter) {
        // If preset exists, reset if filter changed
        this.$emit('resetPreset')
      }
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false, thStyle: 'min-width:100px;' },
        { key: 'progress', sortable: false, searchable: false, thStyle: 'min-width:100px;' },
        { key: 'projectId' },
        { key: 'title' },
        { key: 'projectType', select: true },
        { key: 'domain', directLocalize: true, toggleable: true },
        { key: 'aiRelated', boolean: true, searchable: false },
        { key: 'province', localize: true, searchable: false, toggleable: true },
        { key: 'organization', select: true },
        { key: 'unixGroupName' },
        { key: 'state', localize: true, searchable: false },
        { key: 'accessibleFrom', date: true, searchable: false, toggleable: true },
        { key: 'accessibleUntil', date: true, searchable: false, toggleable: true },
        { key: 'validFrom', date: true, searchable: false },
        { key: 'validUntil', date: true, searchable: false },
        { key: 'pi' },
        { key: 'advisors' },
        { key: 'managers' },
        { key: 'computeResources', array: true, toggleable: true },
        { key: 'storageResources', array: true, toggleable: true }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.toggleable == null) {
          field.toggleable = false
        }
        if (field.toggleable) {
          field.thClass = `${this.lessColumns ? 'd-none' : ''}`
          field.tdClass = `${this.lessColumns ? 'd-none' : ''}`
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.projects.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            return _.has(value, 'label') ? value.label : ''
          }
        }
        if (field.array) {
          field.formatter = (value) => {
            if (value !== null) {
              let arrayLabel = ''
              value.forEach((elem, index) => {
                arrayLabel += ('[' + elem.label.replace('-', '‑') + ']') // make nonbreaking hyphen
                if (index !== value.length - 1) {
                  arrayLabel += ', '
                }
              })
              return arrayLabel
            }
          }
        }
        if (field.localize) {
          field.formatter = (value) => {
            return this.$i18n.t(`${field.key}s.${value}.label`)
          }
        }
        if (field.directLocalize) {
          field.formatter = (value) => {
            if (value?.label) {
              if (this.$i18n.locale === 'en') {
                return value.label.en
              } else if (this.$i18n.locale === 'de') {
                return value.label.de
              } else { // Fallback
                return value.label.de
              }
            } else {
              return '-'
            }
          }
        }
      })
      return fields
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.projects.edit.label')
      } else {
        return this.$i18n.t('comp.projects.add.label')
      }
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.projects.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    projectServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    projectServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  beforeMount () {
  },
  methods: {
    refresh () {
      projectServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['projects-table']) {
        this.$refs['projects-table'].refresh()
      }
    },
    addProject () {
      this.formEdit = false
      this.formReset = _.cloneDeep(this.emptyForm)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    editProject (item) {
      this.formEdit = true
      // Format to JSDates for display
      item.validFrom = item?.validFrom ? new Date(item.validFrom) : null
      item.validUntil = item?.validUntil ? new Date(item.validUntil) : null
      item.accessibleFrom = item?.accessibleFrom ? new Date(item.accessibleFrom) : null
      item.accessibleUntil = item?.accessibleUntil ? new Date(item.accessibleUntil) : null
      this.formReset = _.cloneDeep(item)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.formEdit) {
        // Update Project
        projectServiceForAdminView.update(this.form).then(
          response => {
            // Log changes if any or save comment
            this.logChanges(this.formEdit, this.form.projectId)
            this.makeToast(
              this.$i18n.t('updated.text', { id: response.projectId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.username }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      } else {
        projectServiceForAdminView.create(this.form).then(
          response => {
            // Save initial comment
            this.logChanges(this.formEdit, response.projectId)
            this.makeToast(
              this.$i18n.t('created.text', { id: response.projectId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.title }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = _.cloneDeep(this.formReset)
      this.unixGroup = null
      this.newComment = ''
      this.changes = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deleteProject (project) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            projectServiceForAdminView.delete(project.projectId).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: project.title, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success'
                )
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: project.title }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
      this.formEdit = false
      this.unixGroup = null
      this.newComment = ''
      this.changes = []
    },
    projectItemProvider (ctx) {
      return projectServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    processUnixGroup () {
      if (this.unixGroup) {
        this.form.unixGroupId = this.unixGroup.key
        this.form.unixGroupName = this.unixGroup.label
      } else {
        this.form.unixGroupId = this.formReset.unixGroupId
        this.form.unixGroupName = this.formReset.unixGroupName
      }
    },
    logChanges (forEdit, targetId) {
      // Collect Changes only on Edit
      if (forEdit) {
        Object.keys(this.form).forEach(key => {
          if (!_.isEqual(this.form[key], this.formReset[key])) {
            let newChange = {}
            if (key !== 'domain') {
              newChange = { field: key, before: this.formReset[key], after: this.form[key] }
            } else {
              newChange = {
                field: key,
                before: this?.formReset[key]?.key
                  ? this.formReset[key].key
                  : null,
                after: this?.form[key]?.key
                  ? this.form[key].key
                  : null
              }
            }
            this.changes.push(newChange)
          }
        })
      }
      // Log Changes if any changes (Edit only) or if comment exists (create/edit)
      if (this.changes.length > 0 || this.newComment !== '') {
        commentServiceForAdminView.create({ targetId: targetId.toString(), comment: this.newComment, changes: this.changes }).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: 'Log: ' + response.comment, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.title }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      }
    }
  }
}
</script>
